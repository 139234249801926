import Vue from 'vue'
import VueRouter from 'vue-router'
import API from '@/api/index.js'
import store from '@/store/index.js'
import SSDialog from '@/views/SSDialog.vue'
import SSWelcomeView from '@/views/SSWelcomeView.vue'
import SSLoginView from '@/views/SSLoginView.vue'
import SSSignUpView from '@/views/SSSignUpView.vue'
import SSSignUpCompleteView from '@/views/SSSignUpCompleteView.vue'
import SSPasswordReminderView from '@/views/SSPasswordReminderView.vue'
import SSPasswordReminderViewSent from '@/views/SSPasswordReminderViewSent.vue'
import SSPasswordResetView from '@/views/SSPasswordResetView.vue'
import SSPasswordResetViewDone from '@/views/SSPasswordResetViewDone.vue'
import SSVerifyEmailView from '@/views/SSVerifyEmailView.vue'
import SSDashBoard from '@/views/SSDashBoard.vue'
import SSProfileView from '@/views/SSProfileView.vue'
import SSProfileEditor from '@/views/SSProfileEditor.vue'
import SSCommentListView from '@/views/SSCommentListView.vue'
import SSCommentEditor from '@/views/SSCommentEditor.vue'
import SSNotificationsView from '@/views/SSNotificationsView.vue'
import SSNewsView from '@/views/SSNewsView.vue'
import SSNewsListView from '@/views/SSNewsListView.vue'
import SSNewsEditor from '@/views/SSNewsEditor.vue'
import SSQRScanner from '@/views/SSQRScanner.vue'
import SSUserSelector from '@/views/SSUserSelector.vue'
import SSUserListView from '@/views/SSUserListView.vue'
import SSTreatmentView from '@/views/SSTreatmentView.vue'
import SSTreatmentListView from '@/views/SSTreatmentListView.vue'
import SSTreatmentListFilterView from '@/views/SSTreatmentListFilterView.vue'
import SSCustomerView from '@/views/SSCustomerView.vue'
import SSBrandSelector from '@/views/SSBrandSelector.vue'
import SSCategorySelector from '@/views/SSCategorySelector.vue'
import SSProductSelector from '@/views/SSProductSelector.vue'
import SSManagementView from '@/views/SSManagementView.vue'
import SSSalonListView from '@/views/SSSalonListView.vue'
import SSSalonEditorView from '@/views/SSSalonEditorView.vue'

Vue.use(VueRouter)

/** Tick */
let tick = 0

/** Navigation depth */
let depth = store.state.session.history && store.state.session.history.depth || -1

/** Increment depth */
const incrementDepth = () => {
  return saveDepth(++depth, depth-1)
}

const saveDepth = (val) => {
  store.commit('session', {
    history: Object.assign(store.state.session.history, {
      depth: val
    })
  })
  return val
}

/** Trace route */
const traceRoute = (to, from, callee='') => {
  process.env.NODE_ENV === 'development' &&
    console.info(
      `Route to %c${to.name} %ccaptured by %c${callee}\n`,
      'font-weight:bold',
      'font-weight:normal',
      'font-weight:bold',
      ' to: ',
      to,
      '\nfrom: ',
      from
    )
}

/** Fetch titles in the route and the parent routes */
const getTitle = to => (
  to.matched.reduce((acc, cur) => {
    cur.meta.title && acc.unshift(cur.meta.title)
    return acc
  }, ['milbon Style Stock']).join(' | ')
)

/** Create date parameter */
const getDateParameter = (val, time=false) => {
  const t = time ? ` ${val.getHours()}:${pad(val.getMinutes())}` : ''
  return `${val.getFullYear()}-${pad(val.getMonth()+1)}-${pad(val.getDate())}${t}`
}

/** Padder */
const pad = (num, d=2) => (
  (Math.pow(10, d) + Number(num)).toString().substring(1)
)

/** Get closest ancestor’s meta property */
const getMetaValueOfClosestAncestor = (route, propName, myself=false) => 
        (route.matched.slice(...(myself ? [0] : [0, -1])).findLast(item => item.meta[propName]) || {meta: {}}).meta[propName]

/** Route prototypes */
const routePrototypes = {
  /** Staff selector */
  userSelector: () => ({
    component: SSUserSelector,
    props: route => ({
      /** Treatment ID. ‘new’ as default */
      treatmentId: route.params.treatmentId || 'new',
      prop: route.meta.prop,
      next: route.meta.next
    }),
    async beforeEnter (to, from, next) {
      traceRoute(to, from, 'Staff Selector')

      // Get treatment. If treatment ID is not specified ‘new’ is used as default
      const item = store.getters['treatments/byId'](to.params.treatmentId || 'new')

      // Redirect to the ‘Dashboard’ if the treatment is not exist
      if (!item || !item.salon || !item.customer)
        return next({name: 'Dashboard'})
        
      // Load favorites
      store.dispatch('customers/fetchFavorites', {
        item: {
          id: item.customer
        }})
        // Do nothing even if error occured
        .catch(() => {})

      next()
    },
    meta: {
      /** Page title */
      title: 'スタッフ選択',
      /** Indicate to select stylists or assistants */
      prop: 'stylists'
    }
  }),

  /** User list */
  userList: () => ({
    component: SSUserListView,
    props: route => ({
      // Page title (meta field ‘title’ value)
      title: route.meta.title
    }),
    meta: {
      // Page title
      title: 'スタッフ一覧',
      // Sort order
      order: 'desc',
      // Indicate the role of users to show
      role: '',
      // Indicate requires admin authority
      requiresAdmin: true
    },
    async beforeEnter (to, from, next) {
      traceRoute(to, from, 'Staff List')
      // Fetch all users
      await store.dispatch('staffs/fetchAll', {expired:true})
        .catch(() => store.commit('error', {code: 500, message: 'ユーザー一覧を取得できませんでした'}))
      next()
    }
  }),

  /** Profile View */
  profile: () => ({
    component: SSProfileView,
    props: route => ({
      // Page title (meta field ‘title’ value)
      title: route.meta.title,
      // User’s ID (empty if showing my profile)
      userId: route.params.userId
    }),
    meta: {
      // Page title
      title: 'アカウント'
    }
  }),

  /** Profile Editor */
  profileEditor: () => ({
    component: SSProfileEditor,
    props: route => ({
      // Page title (meta field ‘title’ value)
      title: route.meta.title,
      // User’s ID (empty if showing my profile)
      userId: route.params.userId
    }),
    meta: {
      // Page title
      title: 'アカウント情報変更'
    }
  }),

  /** Comments */
  comments: () => ({
    path: 'comments',
    component: SSCommentListView,
    props: route => ({
      // Page title (meta field ‘title’ value)
      title: route.meta.title,
      // Dialog alignment (Same as ancestor element’s alignment meta field if exist)
      align: getMetaValueOfClosestAncestor(route, 'align') || route.meta.align,
    }),
    meta: {
      // Page title
      title: 'コメント一覧',
      // Dialog alignment Used if the ancestor element has no value
      align: 'right',
      // Indicate to show templates by default
      template: false
    },
    beforeEnter (to, from, next) {
      traceRoute(to, from, 'Comments')

      // Load all comments
      // My comments and salon’s comments
      store.dispatch(`staffs/me/comments/fetch`, {
        id: store.state.staffs.me.id,
        type: 'users'
      })
        .catch(() => store.commit('error', {code: 500, message: 'コメントを取得できませんでした'}))

      store.dispatch(`comments/fetch`, {
        id: store.state.id,
        type: 'salons'
      })
        .catch(() => store.commit('error', {code: 500, message: 'コメントを取得できませんでした'}))

      next()
    },
    children: [
      // Comment editor
      {
        path: ':commentId',
        alias: 'templates/:commentId',
        component: SSCommentEditor,
        props: route => ({
          // Same as params, empty string if commentID is ‘new’
          commentId: route.params.commentId == 'new' ? '' : route.params.commentId,
          // Page title (new or newal)
          title: `${/\/templates\//.test(route.path) ? 'テンプレート' : ''}${route.meta.title}${route.params.commentId === 'new' ? '作成' : '編集'}`,
          // Ancestor’s alignment value
          align: getMetaValueOfClosestAncestor(route, 'align'),
          // Indicate which module to use. Determined by path (comments | staffs/me/comments)
          module: /\/templates\//.test(route.path) ? 'comments' : 'staffs/me/comments'
        }),
        meta: {
          // Prefix of page title
          title: 'コメント'
        },
        beforeEnter (to, from, next) {
          traceRoute(to, from, 'Comment Editor')

          // If requested comment is a salon‘s comment and
          // current user doesn’t have a admin authority, return to the comment list
          if (/\/templates\//.test(to.path) && !store.state.staffs.me.isAdministrator)
            next({path: '/my/comments'})
          else
            next()
        }
      }
    ]
  })
}

/** Actual routes */
const routes = [
  /** Management Dashboard */
  {
    path: '/management',
    name: 'Management',
    component: SSManagementView,
    meta: {
      title: '管理画面',
      requiresAuth: true
    },
    children: [
      /** Salons */
      {
        path: 'salons',
        name: 'Management.salons',
        component: SSSalonListView,
        meta: {
          title: 'サロン',
        },
        beforeEnter: (to, from, next) => {
          traceRoute(to, from, 'Salon List')

          // Fetch all salon data registered in milbon:iD
          store.dispatch('salons/fetchCandidates')

          next()
        },
        children: [
          /** Editor Dialog */
          {
            path: 'edit',
            component: SSDialog,
            props: {
              // Dialog size
              full: true
            },
            children: [
              /** Editor */
              {
                name: 'Management.salons.edit',
                path: ':salonId',
                component: SSSalonEditorView,
                props: route => ({
                  payload: store.getters['salons/byId'](route.params.salonId),
                  back: {
                    name: 'Management.salons'
                  }
                }),
                beforeEnter (to, from, next) {
                  traceRoute(to, from, 'Salon Editor')

                  // Fetch salon information if needed
                  if (to.params.salonId !== 'new')
                    store.dispatch('salons/fetchItem', {id: to.params.salonId})
                      .catch(thrown => {
                        // Alert the error message
                        store.commit('error', thrown)
                        // Go to salon list
                        router.push({name: 'Management.salons'})
                      })

                  next()
                },
                meta: {
                  title: 'サロン詳細'
                }
              }
            ]
          },
          /** My profile */
          {
            path: 'my',
            component: SSDialog,
            props: {
              // Dialog alignment
              align: 'left',
              // Dialog size
              full: true
            },
            children: [
              /** My profile */
              {
                ...routePrototypes.profile(),
                path: 'profile',
                name: 'Management.salons.profile',
                children: [
                  {
                    ...routePrototypes.profileEditor(),
                    path: 'settings',
                    name: 'Management.salons.profile.edit',
                  }
                ]
              }
            ]
          },
        ]
      },
      /** News */
      {
        path: 'news',
        name: 'Management.news',
        component: SSNewsListView,
        meta: {
          title: 'ニュース',
        },
        beforeEnter: (to, from, next) => {
          traceRoute(to, from, 'News List')

          // Fetch all news
          store.dispatch('news/fetch')

          next()
        },
        children: [
          /** My profile */
          {
            path: 'my',
            name: 'Management.news.profile',
            component: SSDialog,
            props: {
              // Dialog alignment
              align: 'left',
              // Dialog size
              full: true
            },
            children: [
              /** My profile */
              {
                ...routePrototypes.profile(),
                name: 'Management.news.profile.edit',
                path: 'profile',
                children: [
                  {
                    ...routePrototypes.profileEditor(),
                    path: 'settings',
                  }
                ]
              }
            ]
          },
          /** News Editor */
          {
            path: ':newsId',
            name: 'News.edit',
            component: SSNewsEditor,
            props: route => ({
              payload: route.params.newsId === 'new' ? undefined : store.getters['news/byId'](route.params.newsId),
              title: route.params.newsId === 'new' ? 'ニュース作成' : 'ニュース編集',
            }),
            meta: {
              title: 'ニュース詳細'
            },
            async beforeEnter (to, from, next) {
              traceRoute(to, from, 'News Editor')

              // Fetch news
              to.params.newsId !== 'new' &&
                await store.dispatch('news/fetchItem', {id: to.params.newsId})

              // Go next
              next()
            },
          }
        ]
      }
    ]
  },
  /** Dashboard */
  {
    path: '/',
    name: 'Dashboard',
    component: SSDashBoard,
    async beforeEnter (to, from, next) {
      traceRoute(to, from, 'Dashboard')

      // Fetch all users
      store.dispatch('staffs/fetchAll', {expired:true})

      const today = new Date

      // Get all available items
      store.dispatch('treatments/fetchAll', {
        query: {
          id: store.state.staffs.me.isAdministrator ? '' : store.state.staffs.me.id,
          datestart: getDateParameter(new Date(today.getTime() - 96*3600*1000)),
          dateend: getDateParameter(today)
        },
        collection: 'mine'
      })
        .then(() => {
          // Clean treatment and customer data
          store.commit('treatments/clean')
          store.commit('customers/clear', {
            exclude: store.getters['treatments/collection']('mine').items
                      .map(item => item.customer)
          })

          // Show contents
          store.commit('state', {processing: false})
        })
        .catch(() => store.commit('error', {code: 500, message: '施術情報を取得できませんでした'}))

      // Go next
      next()
    },
    meta: {
      requiresAuth: true
    },
    children: [
      /** My profile, comments, notifications, news and staffs */
      {
        path: 'my',
        component: SSDialog,
        props: route => ({
          // Dialog alignment (comments,notifications:right/profile:left)
          align: /\/(staffs|profile)/.test(route.path) ? 'left' : 'right',
          // Dialog size
          full: true
        }),
        children: [
          /** My profile */
          {
            ...routePrototypes.profile(),
            path: 'profile',
            name: 'Profile.mine',
            children: [
              {
                ...routePrototypes.profileEditor(),
                path: 'settings',
                name: 'Profile.settings.mine',
              }
            ]
          },
          /** My comments */
          {
            ...routePrototypes.comments(),
            path: 'comments',
            name: 'Comments.mine',
          },
          /** Staffs */
          {
            ...routePrototypes.userList(),
            path: 'staffs',
            name: 'Staffs',
            children: [
              /** Profile Editor */
              {
                ...routePrototypes.profileEditor(),
                path: 'new',
                name: 'Profile.new',
                props: route => ({
                  // Page title (meta field ‘title’ value)
                  title: route.meta.title,
                  // User’s ID (empty if showing my profile)
                  userId: 'new'
                }),
                meta: {
                  // Page title
                  title: '新規スタッフ追加'
                }
              },
              /** Profile */
              {
                ...routePrototypes.profile(),
                path: ':userId',
                name: 'Profile',
                props: route => ({
                  // Page title (User name). The user must be undefined in removing user process
                  title: (item => item ? `${item.lastName} ${item.firstName}` : '')(store.getters['staffs/byId'](route.params.userId)),
                  // User’s ID (empty if showing my profile)
                  userId: route.params.userId
                }),
                children: [
                  {
                    ...routePrototypes.profileEditor(),
                    path: 'settings',
                    name: 'Profile.settings',
                    meta: {
                      // Page title
                      title: 'スタッフ情報変更'
                    }
                  }
                ]
              },
            ]
          },
          /** Norifications */
          {
            path: 'notifications',
            name: 'Notifications',
            component: SSNotificationsView,
            porps: {
              // Dialog alignment
              align: 'right'
            },
            meta: {
              title: 'お知らせ一覧'
            },
            beforeEnter (to, from, next) {
              traceRoute(to, from, 'Notifications')
              next()
            }
          },
          /** News */
          {
            path: 'news/:newsId',
            name: 'News',
            component: SSNewsView,
            props: route => ({
              // News Id
              newsId: route.params.newsId,
              // Dialog alignment
              align: 'right'
            }),
            meta: {
              title: 'お知らせ'
            },
            beforeEnter (to, from, next) {
              traceRoute(to, from, 'News')

              // Fetch news
              store.dispatch('news/fetchItem', {id: to.params.newsId})

              next()
            }
          },
        ]
      },
      /** Treatments */
      {
        path: 'treatments',
        component: SSDialog,
        props: {
          // Dialog size
          full: true
        },
        children: [
          /** QR */
          {
            name: 'QR',
            path: 'qr',
            component: SSQRScanner,
            beforeEnter (to, from, next) {
              traceRoute(to, from, 'QR')

              // Reset today’s treatment
              store.commit('treatments/remove', {items: [{id:'new'}]})

              // Add new treatment and add myself to stylists/assistants
              new RegExp('(2|3)').test(store.state.staffs.me.role) &&
                store.commit('treatments/add', {
                  items: [{
                    id: 'new',
                    [store.state.staffs.me.role === 2 ? 'stylists' : 'assistants']:
                      [{id: store.state.staffs.me.id}]
                  }]
                })

              next()
            },
            meta: {
              title: 'QRコードをスキャン'
            }
          },
          /** Stylists */
          {
            ...routePrototypes.userSelector(),
            path: 'stylists',
            name: 'Stylists.todays',
            props: route => ({
              treatmentId: 'new',
              prop: route.meta.prop,
              next: route.meta.next
            }),
            meta: {
              next: {name: 'Assistants.todays'},
              prop: 'stylists',
              title: 'スタイリスト選択'
            }
          },
          /** Assistants */
          {
            ...routePrototypes.userSelector(),
            path: 'assistants',
            name: 'Assistants.todays',
            props: route => ({
              treatmentId: 'new',
              prop: route.meta.prop,
              next: route.meta.next
            }),
            meta: {
              next: {name:'Treatment', params:{treatmentId: 'new'}},
              prop: 'assistants',
              title: 'アシスタント選択'
            }
          },
          /** Search */
          {
            path: 'search',
            name: 'Treatments',
            component: SSTreatmentListView,
            props: route => ({
              customer: route.query.customer ? true : false
            }),
            beforeEnter (to, from, next) {
              next(traceRoute(to, from, 'Treatments'))

              try {
                // Search treatments
                // Page property of the search filter is not 1 then set to 1 otherwise start searching
                if (from.name !== 'Treatments.filter')
                  if (store.state.treatments.filter.page !== 1)
                    store.commit('treatments/filter/state', {page: 1})
                  else
                    store.dispatch('treatments/search')
              } catch (thrown) {
                store.commit('error', thrown)
              }

              next()
            },
            meta: {
              title: '施術履歴'
            }
          },
          /** Filter */
          {
            path: 'search/filter',
            name: 'Treatments.filter',
            component: SSTreatmentListFilterView,
            beforeEnter: async (to, from, next) => {
              next(traceRoute(to, from, 'Treatments Filter'))
            },
            meta: {
              title: '施術履歴の絞り込み'
            }
          },
          /** Treatment */
          {
            path: ':treatmentId',
            name: 'Treatment',
            component: SSTreatmentView,
            props: route => ({
              // Treatment ID
              treatmentId: route.params.treatmentId,
              // Indicate editable
              editable: true,
              // Go back route (route.meta.back or ‘Dashboard’)
              back: route.meta.back || {name: 'Dashboard'}
            }),
            meta: {
              /** Page title */
              title: '施術情報入力',
              /** Indicatae go back route */
              back: {}
            },
            async beforeEnter (to, from, next) {
              traceRoute(to, from, 'Treatment')

              // No treatment ID, redirect to ‘Dashboard’
              if (!to.params.treatmentId)
                return next({name: 'Dashboard'})

              // If treatment ID is ‘new’, create new item and redirect to it
              if (to.params.treatmentId === 'new') {
                // Set initial message
                store.commit('treatments/add', {
                  items: [{
                    id: 'new',
                    message: (store.getters['staffs/me/comments/defaults'][0] || {message: ''}).message
                  }]
                })

                return await store.dispatch('treatments/save', {id:'new'})
                  .then(response => {
                    // Send GA event
                    window.dataLayer && window.dataLayer.push({
                      event: 'add_treatment',
                      salon_id: response.salon,
                      customer_id: response.customer,
                      item_id: response.id
                    })

                    // Go next
                    next({name: 'Treatment', params: {treatmentId: response.id}})
                  })
                  .catch(thrown => store.commit('error', {
                    code: 500,
                    message: '施術情報を作成できませんでした',
                    thrown: thrown
                  }))
              }

              // Fetch the item
              store.dispatch('treatments/fetchItem', {id:to.params.treatmentId})
                .catch(thrown => store.commit('error', {
                  code: 500,
                  message: '施術情報が見つかりませんでした',
                  thrown: thrown
                }))

              next()
            },
            children: [
              /** Stylists */
              {
                ...routePrototypes.userSelector(),
                path: 'stylists',
                meta: {
                  prop: 'stylists',
                  title: 'スタイリスト選択'
                }
              },
              /** Assistants */
              {
                ...routePrototypes.userSelector(),
                path: 'assistants',
                meta: {
                  prop: 'assistants',
                  title: 'アシスタント選択'
                }
              },
              /** Categories */
              {
                path: 'products/categories',
                component: SSCategorySelector,
                query: {
                  prop: 'others',
                  index: '0'
                },
                meta: {
                  /** Page title */
                  title: 'カテゴリーを選択'
                }
              },
              /** Brands */
              {
                path: 'products/categories/:categoryId',
                component: SSBrandSelector,
                query: {
                  prop: 'shanpoo',
                  index: '0'
                },
                beforeEnter (to, from, next) {
                  traceRoute(to, from, 'Brand Selector')

                  // Reacuire all products
                  store.dispatch('products/fetch', store.state.id)

                  next()
                },
                meta: {
                  /** Page title */
                  title: 'ブランドを選択'
                }
              },
              /** Products */
              {
                path: 'products/categories/:categoryId/brands/:brandId',
                component: SSProductSelector,
                props: route => ({
                  treatmentId: route.params.treatmentId
                }),
                query: {
                  prop: 'shanpoo',
                  index: '0'
                },
                meta: {
                  /** Page title */
                  title: '商品を選択'
                }
              }
            ]
          },
          /** Customer */
          {
            path: 'customers/:customerId',
            name: 'Customer',
            component: SSCustomerView,
            props: route => ({
              customerId: route.params.customerId
            }),
            beforeEnter: async (to, from, next) => {
              traceRoute(to, from, 'Customer')

              // Today
              const today = new Date()

              // Fetch customer’s treatments
              store.dispatch('treatments/fetchAll', {
                query: {
                  // Stylist or Assistant ID
                  id: store.state.staffs.me.isAdministrator ? undefined : [store.state.staffs.me.id],
                  // Customer IDs
                  customer: to.params.customerId,
                  // Narrow down to a year
                  datestart: `${today.getFullYear()-1}-${(today.getMonth()+1).toString().padStart(2,0)}-${today.getDate().toString().padStart(2,0)}`,
                },
                collection: to.params.customerId
              })

              next()
            },
            meta: {
              // Page title
              title: '施術履歴'
            }
          }
        ]
      },
    ]
  },
  /** Welcome */
  {
    path: '/welcome',
    name: 'Welcome',
    component: SSWelcomeView,
    meta: {
      title: 'Welcome'
    }
  },
  /** Verify password */
  {
    path: '/signup',
    name: 'Signup',
    component: SSPasswordResetView,
    props: route => ({
      title: route.meta.title,
      text: 'パスワードを登録して、アカウント作成を完了してください。'
    }),
    meta: {
      // Page title
      title: 'パスワード登録'
    },
    beforeEnter: async (to, from, next) => {
      !to.query.code ? 
        next({name:'Welcome'}) :
        next()
    },
  },
  /** Sign up */
  {
    path: '/signup/:salonId/:token',
    name: 'SignUp',
    component: SSSignUpView,
    props: route => ({
      title: route.meta.title,
      salonName: route.query.salon,
      token: route.params.token,
      payload: {
        salonId: route.params.salonId,
        firstName: '',
        lastName: '',
        email: '',
        role: 2,
        useSalonToken: false,
        isAdministrator: false,
        data: {
          verified: false
        }
      }
    }),
    meta: {
      // Page title
      title: '新規アカウント登録'
    },
    beforeEnter: async (to, from, next) => {
      next()
    },
  },
  /** Sign up complete */
  {
    path: '/signup/:salonId/:token/sent',
    name: 'SignUp.sent',
    component: SSSignUpCompleteView,
    meta: {
      // Page title
      title: '送信完了しました'
    },
    beforeEnter: async (to, from, next) => {
      next()
    },
  },
  /** Accounts */
  {
    path: '/account',
    name: 'Account',
    component: SSDialog,
    children: [
      /** Login */
      {
        path: 'login',
        name: 'Login',
        component: SSLoginView,
        meta: {
          title: 'ログイン'
        },
        props: route => ({
          payload: {
            salon: '',
            email: route.query.m || '',
            password: decodeURIComponent(route.query.p || ''),
            /** Whether to compound */
            compound: route.query.c !== undefined || false
          }
        }),
        beforeEnter (to, from, next) {
          next(traceRoute(to, from, 'Login'))
        }
      },
      /** Password reminder */
      {
        path: 'password-reminder',
        name: 'PasswordReminder',
        component: SSPasswordReminderView,
        meta: {
          title: 'パスワード再設定'
        },
        beforeEnter (to, from, next) {
          next(traceRoute(to, from, 'Password Reminder'))
        }
      },
      /** Password reminder sent */
      {
        path: 'password-reminder/sent',
        name: 'PasswordReminder.sent',
        component: SSPasswordReminderViewSent,
        meta: {
          title: '送信完了しました'
        },
        beforeEnter (to, from, next) {
          next(traceRoute(to, from, 'Password Reminder Sent'))
        }
      },
      /** Password Reset */
      {
        path: 'password-reset',
        name: 'PasswordReset',
        component: SSPasswordResetView,
        beforeEnter: async (to, from, next) => {
          !to.query.code ? 
            next({name:'Welcome'}) :
            next()
        }
      },
      /** Password Reset Done */
      {
        path: 'password-reset/done',
        name: 'PasswordReset.done',
        component: SSPasswordResetViewDone
      },
      /** Email verification */
      {
        path: 'verify-email',
        name: 'Verify.email',
        component: SSVerifyEmailView,
        meta: {
          title: 'メールアドレス変更'
        }
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

/** Filter watcher */
store.watch(state => [
  state.treatments.filter.id,
  state.treatments.filter.datestart,
  state.treatments.filter.dateend,
  state.treatments.filter.status,
  state.treatments.filter.sort,
  state.treatments.filter.page
], () => {
  // Do search
  store.dispatch('treatments/search')
})

/** Global guard */
router.beforeEach(async (to, from, next) => {
  // Refresh token once in 5 minutes
  if (API.hasAccessToken() && new Date().getTime() - tick > 1*60*1000 &&
        to.matched.some(record => record.meta.requiresAuth))
    try {
      await API.refreshToken()
      // Reset timer
      tick = new Date().getTime()
    } catch (thrown) {
      store.commit('error', thrown)
      return
    } finally {
      // Reset timer
      tick = new Date().getTime()
    }

  // First access, load all necessary data
  if (to.matched.some(record => record.meta.requiresAuth) &&
        (!store.state.id || from.name === 'Login')) {
    /** Set progressing */
    store.commit('state', {processing: true})

    try {
      // Get user info signed in
      await store.dispatch('staffs/me/fetch')

      // Fetch salon
      await store.dispatch('fetch', store.state.staffs.me.salon)

      // Set state of salon’s comments
      store.commit('comments/state', {id: store.state.id ,type: 'salons'})
      // Set state of user’s comments
      store.commit('staffs/me/comments/state', {id: store.state.staffs.me.id})

      // Fetch all user’s comments
      store.dispatch('staffs/me/comments/fetch', {id: store.state.staffs.me.id})

      // Clear filter
      // Set stylist ID if the user is not an administrator
      store.commit('treatments/filter/state', {
        id: store.state.staffs.me.isAdministrator ? [] : [store.state.staffs.me.id]
      })

      // Get all products
      store.dispatch('products/fetch', store.state.id)
        .catch(() => {throw({code: 500, message: '商品情報を取得できませんでした'})})
    } catch {
      /** Set progressing */
      store.commit('state', {processing: false})

      try {
        // Signout and go to welcome page
        await API.signOut()
        next({name: 'Welcome', replace:true})
      } catch {
        // Don't show errors on the console
        return 
      }
      return
    }

    // Go to management console if the current salon is management
    if (!/^\/management/.test(to.path) && store.getters['isManagement'])
      return next({name: 'Management.salons'})
  }

  // Check the authorization if the destination requires admin authority
  // If not authorized go to ‘Dashboard’
  if (to.matched.some(record => record.meta.requiresAdmin) &&
        !store.state.staffs.me.isAdministrator)
    return next({name:'Dashboard'})

  // Save history direction
  store.commit('session', {
    history: Object.assign(store.state.session.history || {}, {
      action: !history.state || isNaN(history.state.depth) ? 'forward' :
                (history.state.depth < depth ? 'back' : 'forward')
    })
  })

  // Set page title
  document.title = getTitle(to)

  // Set route to go back if the destination is treatment
  if (to.params.treatmentId)
      to.meta.back = /\/(customer|search)$/.test(from.path) &&
                      store.state.session.history.action !== 'back' ? {} : {name: 'Dashboard'}

  // If the destination is the salon list, load salons.
  if (to.name === 'Management.salons') {
    // Set default parameters
    if (!Object.keys(to.query).length)
      return next({name: 'Management.salons', query: {page: 1}})

    // Fetch salons
    store.dispatch('salons/search', to.query)
  }

  // If to is ‘Dashboard’ and from is Dashboard’s child
  // Call Dashboard’s beforeEach method
  if (to.name === 'Dashboard' &&
        from.matched.some(record => record.name === 'Dashboard'))
    return router.getRoutes().find(item => item.name ===  'Dashboard').beforeEnter(to, from, next)
  else
    // Go next
    next()
})

router.afterEach(() => {
  setTimeout(() =>  {
    // Add depth to history’s state
    if (!history.state || isNaN(history.state.depth))
      history.replaceState({
        ...history.state,
        depth: incrementDepth(depth)
      }, '')
    else
      depth = saveDepth(history.state.depth)
  }, 0)
})

export default router
