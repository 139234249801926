<template>
  <div class="ss-management-view">
    <!-- Header -->
    <div class="ss-management-view-header">
      <div class="ss-management-view-header__content">
        <div
          class="ss-management-view-header__graphic"
          @click="$router.push({
            path: `${$router.currentRoute.path}/my/profile`
          }).catch(() => {})"
        >
          <oct-media
            :image="$store.state.profileIcon"
            :alt="`${me.lastName} ${me.firstName}`"
            class="ss-management-view-header__image"
          />
        </div>

        <!-- Title -->
        <div class="ss-management-view-header__title">
          <!-- Switch -->
          <div class="ss-comment-list__actions">
            <oct-switch 
              :texts="['サロン管理', 'ニュース管理']"
              v-model="currentContent"
            />
          </div>
        </div>

        <!-- Actions -->
        <div class="ss-management-view-header__actions">
          <!-- Search Input -->
          <oct-text-field
            block
            class="ss-management-view-header__input"
            :value="$route.query.q || ''"
            @change="
              $router.push({
                name: 'Management.salons',
                query: { q: $event.target.value }
              }).catch(() => {})
            "
          >
            <template #leading-icon>
              <oct-icon icon="search" />
            </template>
          </oct-text-field>

          <div class="ss-management-view-header__action">
            <oct-icon icon="exit" @click.native="signOut" />
          </div>
        </div>
      </div>
    </div>

    <!-- Child Component -->
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import API from '@/api/index.js'
import OctIcon from '@/components/icon/OctIcon.vue'
import OctMedia from '@/components/media/OctMedia.vue'
import OctTextField from '@/components/text-field/OctTextField.vue'
import OctSwitch from '@/components/switch/OctSwitch.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'

export default {
  name: 'SSManagementView',
  mixins: [
    OctPrototypeView
  ],
  components: {
    OctIcon,
    OctMedia,
    OctTextField,
    OctSwitch
  },
  computed: {
    /** Indicate current content */
    currentContent: {
      get () {
        return /^\/management\/news/.test(this.$router.currentRoute.path)
      },
      set (val) {
        const soFar = /^\/management\/news/.test(this.$router.currentRoute.path)

        // Ignore same value
        if (val === soFar) return

        // Change content
        this.$router.push({name: `Management.${['salons', 'news'][Number(val)]}`})
      }
    },
    ...mapState({
      me: state => state.staffs.me,
    }),
  },
  methods: {
    /** Sign out */
    signOut () {
      API.signOut()
        .then(() => {
          this.$router.replace({name:'Welcome'}).catch(() => {})
        })
        .catch(thrown => this.$store.commit('error', thrown))
    }
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-management-view {
  padding: 0 0 oct-rem(84);
  box-sizing: border-box;
  position: relative;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  padding-bottom: 0;
}
}

.ss-management-view-header {
  position: sticky;
  top: 0;
  background-color: $oct-theme--surface;
  z-index: 2;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  position: static;
  margin-bottom: oct-rem(32);
}
}

.ss-management-view-header__content {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  height: oct-rem(80);
  padding: oct-rem(16);

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  height: oct-rem(120);
  padding: oct-rem(32);
}
}

.ss-management-view-header__graphic {
  flex: 0 0 oct-rem(48);
  max-width: oct-rem(48);
  width: oct-rem(48);
  height: oct-rem(48);
  border: 1px solid $oct-theme--neutral-20;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  flex: 0 0 oct-rem(56);
  max-width: oct-rem(56);
  width: oct-rem(56);
  height: oct-rem(56);
}
}

.ss-management-view-header__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ss-management-view-header__title {
  flex: 1 1 auto;
  text-align: center;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  font-size: oct-rem(20);
}
}

.ss-management-view-header__actions {
  position: relative;
  margin-left: oct-rem(48-32);
  flex: 0 0 auto;
  gap: oct-rem(12);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: $oct-theme--surface;
  z-index: 2;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  gap: oct-rem(24);
}
}

.ss-management-view-header__action {
  width: oct-rem(32);
  height: oct-rem(32);
  cursor: pointer;

  .oct-icon {
    fill: $oct-theme--neutral;
    width: 100%;
    height: 100%;
  }

  &--activated {
    position: relative;

    .oct-icon {
      fill: $oct-theme--primary;
    }

    &:after {
      content: "";
      position: absolute;
      top: oct-rem(-2);
      right: oct-rem(-2);
      width: oct-rem(12);
      height: oct-rem(12);
      background-color: $oct-theme--neutral-30;
      border: oct-rem(2) solid $oct-theme--surface;
      border-radius: 50%;
    }
  }
}

.ss-management-view-header__input {
  display: none;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  display: block;
  max-width: oct-rem(240);

  ::v-deep .oct-text-field__input {
    border-radius: oct-rem(24);
  }
}
}
</style>