var render = function render(){var _vm=this,_c=_vm._self._c;return _c('oct-dialog',{staticClass:"ss-user-list",attrs:{"full":"","title":_vm.title,"align":"left","icon":"arrow-left"},on:{"dismiss":_vm.$_octPrototypeView_goBack},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(!_vm.$data.$_ssULV_selectedItems.length)?_c('div',{staticClass:"ss-user-list-footer__actions"},[_c('div',{staticClass:"ss-user-list-footer__section"},[_c('oct-text-field',{staticClass:"ss-user-list-footer__text-field",attrs:{"value":_vm.$data.$_ssULV_signUpURL,"block":""}}),_c('oct-button',{staticClass:"ss-user-list-footer__icon-button",attrs:{"icon":"refresh","outline":""},nativeOn:{"click":function($event){return _vm.$_ssUSV_setSignUpURL(true)}}}),_c('oct-button',{staticClass:"ss-user-list-footer__button",attrs:{"outline":""},nativeOn:{"click":function($event){return _vm.$_ssUSV_copyToClipboard(_vm.$data.$_ssULV_signUpURL)}}},[_vm._v(" URLコピー ")])],1),_c('div',[_c('oct-button',{attrs:{"block":"","size":"large"},nativeOn:{"click":function($event){_vm.$router.push({
            path: `${_vm.$route.path}/new`
          }).catch(() => {})}}},[_vm._v(" 新規スタッフ追加 ")])],1)]):_c('div',{staticClass:"ss-user-list-footer__actions"},[_c('div',{staticClass:"ss-user-list-footer__section"},[_c('div',{staticClass:"ss-user-list-footer__text"},[_c('oct-checkbox',{staticClass:"ss-user-list-footer__checkbox",attrs:{"checked":""}}),_c('p',{staticClass:"ss-user-list-footer__secondary-text"},[_vm._v(" "+_vm._s(_vm.$data.$_ssULV_selectedItems.length)+" 個の項目を選択中 ")])],1),_c('oct-select',{attrs:{"block":"","dense":"","items":[
            {
              value: 0,
              text: '-- 選択してください --'
            },
            {
              value: 1,
              text: '停止中'
            },
            {
              value: 4,
              text: '稼働中'
            }
          ]},model:{value:(_vm.$data.$_ssULV_selectedStatus),callback:function ($$v) {_vm.$set(_vm.$data, "$_ssULV_selectedStatus", $$v)},expression:"$data.$_ssULV_selectedStatus"}})],1),_c('div',[_c('oct-button',{attrs:{"block":"","size":"large"},nativeOn:{"click":function($event){return _vm.$_ssUSV_onClickChangeMultipleStatus.apply(null, arguments)}}},[_vm._v(" 保存 ")])],1)])]},proxy:true}])},[_c('div',{staticClass:"ss-user-list__actions"},[_c('oct-select',{attrs:{"block":"","dense":"","items":[
        {
          value: 'desc',
          text: '新しい順'
        },
        {
          value: 'asc',
          text: '古い順'
        }
      ]},model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}}),_c('oct-select',{attrs:{"block":"","dense":"","items":[
        {
          value: '',
          text: '全てのスタッフ'
        },
        {
          value: '1',
          text: 'オーナー'
        },
        {
          value: '2',
          text: 'スタイリスト'
        },
        {
          value: '3',
          text: 'アシスタント'
        },
        {
          value: '4',
          text: 'スタッフ'
        }
      ]},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}})],1),_c('oct-list',_vm._l((_vm.items),function(item){return _c('oct-list-item',{key:item.id,attrs:{"media":{
        image: _vm.$_octPrototypeView_getProfileIconSafely(item.profileIcon)
      },"title":`${item.lastName} ${item.firstName}`,"text":_vm.$_octPrototypeView_getRoleNameById(item.role),"value":item.id,"selectable":"","disabled":item.status === 2},nativeOn:{"click":function($event){_vm.$router.push({
        path: `${_vm.$route.path}/${item.id}`
      }).catch(() => {})}},scopedSlots:_vm._u([{key:"meta",fn:function(){return [(item.status === 2)?_c('oct-chip',{attrs:{"selected":""}},[_vm._v(" 未登録 ")]):(item.status === 1)?_c('span',{staticClass:"ss-user-list-stutus-indicator ss-user-list-stutus-indicator--danger"},[_vm._v(" 停止中 ")]):(item.data.verified === false)?_c('oct-chip',{attrs:{"outline":"","selected":""}},[_vm._v(" 未承認 ")]):_c('span',{staticClass:"ss-user-list-stutus-indicator ss-user-list-stutus-indicator--success"},[_vm._v(" 稼働中 ")])]},proxy:true},{key:"trailing-icon",fn:function(){return [_c('oct-icon',{attrs:{"icon":"chevron-right"}})]},proxy:true}],null,true),model:{value:(_vm.$data.$_ssULV_selectedItems),callback:function ($$v) {_vm.$set(_vm.$data, "$_ssULV_selectedItems", $$v)},expression:"$data.$_ssULV_selectedItems"}})}),1),_c('transition',{attrs:{"name":_vm.transitionName}},[_c('router-view',{key:_vm.$route.name,staticClass:"ss-dialog__content"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }