<template>
  <div class="oct-table" @click="handleOutsideClick" :class="{
    'oct-table--overflow': isOverflow,
    'oct-table--scroll-start': isScrollStart,
    'oct-table--scroll-end': isScrollEnd
  }">
    <!-- Table -->
    <table class="oct-table__table">
      <!-- Header-->
      <thead class="oct-table__header">
        <tr
          v-for="(headerRow, rowIndex) in headers"
          :key="rowIndex"
          class="oct-table__header-row"
        >
          <th
            v-for="(header, index) in headerRow"
            :key="index"
            :colspan="header.colspan || 1"
            :class="{
              'oct-table__header-cell--fixed': isFixedColumn(index, headerRow),
              'oct-table__header-cell--end': isFixedColumn(index, headerRow) && !isFixedColumn(index+1, headerRow),
              'oct-table__header-cell--active': activeFilterGroupIndex === index
            }"
            class="oct-table__header-cell"
            :style="getCellStyle(index, headerRow)"
            ref="headerCells"
          >
            <div class="oct-table__text-container">
              <span class="oct-table__cell-text">
                {{ header.text }}
              </span>

              <!-- Filter icon, only displayed for the last row of headers if filters are defined -->
              <oct-icon
                icon="filter"
                v-if="rowIndex === headers.length - 1 && filters && filters[index]"
                @click.native="toggleFilterMenu(index, $event)"
                class="oct-table__filter-icon"
              />
            </div>
          </th>
        </tr>
      </thead>

      <!-- Body -->
      <tbody class="oct-table__body">
        <tr
          v-for="(row, rowIndex) in tableData"
          :key="rowIndex"
          class="oct-table__row"
          ref="firstRow"
          @click="$emit('click:row', row)"
        >
          <td
            v-for="(cell, cellIndex) in row"
            :key="cellIndex"
            :class="{
              'oct-table__cell--fixed': isFixedColumn(cellIndex, tableData[0]),
              'oct-table__cell--end': isFixedColumn(cellIndex, tableData[0]) && !isFixedColumn(cellIndex+1, tableData[0])
            }"
            class="oct-table__cell"
            :data-text="cell"
            :style="getCellStyle(cellIndex, tableData[0])"
          >
            <div class="oct-table__text-container">
              <span class="oct-table__cell-text">
                {{ cell }}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Menu -->
    <div
      :style="filterMenuStyle"
      class="oct-table__filter-menu"
      @click.stop
      v-if="isFilterMenuVisible"
      ref="filterMenu"
    >
      <div
        v-for="(group, groupIndex) in filters[activeFilterIndex]"
        :key="groupIndex"
        class="oct-table__filter-menu-section"
      >
        <!-- Heading -->
        <div
          v-if="group.groupName"
          class="oct-table__filter-menu-heading"
        >
          {{ group.groupName }}
        </div>

        <div
          v-for="(item, itemIndex) in group.items"
          :key="itemIndex"
          @click="menuItemClicked(item)"
          class="oct-table__filter-menu-item"
          :class="{ 'oct-table__filter-menu-item--active': item.active }"
        >
          <oct-icon
            icon="done"
            v-if="item.active"
          />

          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OctIcon from '@/components/icon/OctIcon.vue'

export default {
  name: "OctTable",
  components: {
    OctIcon
  },
  props: {
    /**
     * The headers for the table columns. It can support multiple rows of headers.
     * Each header object should contain `text` and optionally `colspan`.
     * @type {Array}
     */
    headers: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * The data to be displayed in the table.
     * @type {Array}
     */
    tableData: {
      type: Array,
      required: true
    },
    /**
     * Definitions for the filters of the columns.
     * Each filter object should contain `groupName` and `items`.
     * Each item object should contain `text`, `active`, and `payload`.
     * @type {Array}
     */
    filters: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * The number of columns to be fixed.
     * @type {Number}
     */
    fixedColumns: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      isFilterMenuVisible: false, // Visibility state of the filter menu
      filterMenuStyle: {
        top: '0px',
        left: '0px'
      },
      activeFilterIndex: null, // Index of the currently active filter menu
      columnWidths: [], // Stores the calculated widths of the table columns
      isOverflow: false, // Indicates if the table is overflowing
      isScrollStart: true, // Indicates if the table is scrolled to the start
      isScrollEnd: false // Indicates if the table is scrolled to the end
    };
  },
  computed: {
     /**
     * Returns the index of the filter group that contains an active item.
     * @returns {number|null} - The index of the filter group, or null if no active item is found.
     */
     activeFilterGroupIndex() {
      for (let i = 0; i < this.filters.length; i++) {
        const groups = this.filters[i];
        for (let j = 0; j < groups.length; j++) {
          const group = groups[j];
          if (group.items.some(item => item.active)) {
            return i;
          }
        }
      }
      return null;
    }
  },
  mounted() {
    this.checkScrollPosition();
    // Check if the table overflows the container when mounted
    this.checkTableOverflow();
    this.$nextTick(() => {
      // Calculate the column widths after rendering
      this.calculateColumnWidths();
    });
    // Add event listeners to handle resizing and scrolling
    window.addEventListener('resize', () => {
      this.calculateColumnWidths();
      this.checkTableOverflow();
    });
    window.addEventListener('resize', this.handleScroll);
    this.$el.addEventListener('scroll', this.checkScrollPosition);
    this.$el.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    // Remove event listeners before the component is destroyed
    window.removeEventListener('resize', this.calculateColumnWidths);
    window.removeEventListener('resize', this.handleScroll);
    this.$el.removeEventListener('scroll', this.handleScroll);
    this.$el.removeEventListener('scroll', this.checkScrollPosition);
  },
  watch: {
    // Recalculate column widths when headers change
    headers() {
      this.$nextTick(() => {
        this.calculateColumnWidths();
      });
    },
    // Recalculate column widths when table data changes
    tableData() {
      this.$nextTick(() => {
        this.calculateColumnWidths();
      });
    }
  },
  methods: {
    /**
     * Checks the scroll position of the table and adds classes to indicate the scroll state.
     */
    checkScrollPosition() {
      const { scrollLeft, scrollWidth, clientWidth } = this.$el;
      // Update scroll start state
      this.isScrollStart = scrollLeft === 0;
      // Update scroll end state
      this.isScrollEnd = scrollLeft + clientWidth >= scrollWidth;
    },
    /**
     * Checks if the table is overflowing the container and adds a class if necessary.
     */
    checkTableOverflow() {
      // Update overflow state based on whether the table width exceeds the container width
      this.isOverflow = this.$el.scrollWidth > this.$el.clientWidth;
    },
    /**
     * Toggles the visibility of the filter menu.
     * @param {number} index - The index of the column to filter.
     * @param {Event} event - The click event.
     */
    toggleFilterMenu(index, event) {
      event.stopPropagation(); // Stop the event from propagating to the parent div
      // Get the dimensions of the clicked button and table to position the filter menu correctly
      this.activeFilterIndex = index; // Set the active filter index
      this.isFilterMenuVisible = !this.isFilterMenuVisible; // Toggle the filter menu visibility

      this.$nextTick(() => {
        const menuElement = this.$refs.filterMenu; // Reference to the filter menu element

        if (!menuElement) return;

        const tableRect = this.$el.getBoundingClientRect();
        const buttonRect = event.target.getBoundingClientRect();
        const scrollLeft = this.$el.scrollLeft;

        // Default position
        let top = buttonRect.bottom - tableRect.top;
        let left = buttonRect.left - tableRect.left + scrollLeft;

        // Get the actual menu dimensions dynamically
        const menuWidth = menuElement.offsetWidth;
        const menuHeight = menuElement.offsetHeight;

        // Adjust to prevent overflowing beyond the right edge of the table
        if (left + menuWidth > tableRect.width) {
          left = tableRect.width - menuWidth - 8;
        }

        // Adjust to prevent overflowing beyond the bottom edge of the table
        if (top + menuHeight > tableRect.height) {
          top = tableRect.height - menuHeight;
        }

        this.filterMenuStyle = {
          top: `${top}px`,
          left: `${left}px`,
        };
      });
    },
    /**
     * Emits an event when a filter menu item is clicked.
     * @param {Object} item - The clicked menu item.
     */
    menuItemClicked(item) {
      this.$emit('click:menu', item.payload); // Emit an event with the clicked item's payload
      this.isFilterMenuVisible = false; // Hide the filter menu
    },
    /**
     * Handles click events outside the filter menu to hide it.
     */
    handleOutsideClick() {
      this.isFilterMenuVisible = false; // Hide the filter menu when clicking outside
    },
    /**
     * Handles scroll events to hide the filter menu.
     */
    handleScroll() {
      if (this.isFilterMenuVisible) {
        this.isFilterMenuVisible = false; // Hide the filter menu on scroll to prevent misalignment
      }
    },
    /**
     * Checks if a column is a fixed column, considering colspan.
     * @param {number} index - The index of the column.
     * @param {Array} row - The row to check.
     * @returns {boolean} - True if the column is fixed, false otherwise.
     */
    isFixedColumn(index, row) {
      let count = 0;
      // Iterate over the row to determine if the column falls within the fixed columns
      for (let i = 0; i < row.length; i++) {
        const colspan = row[i].colspan || 1;
        count += colspan;
        if (index < count) {
          return i < this.fixedColumns;
        }
      }
      return false;
    },
    /**
     * Returns the style for a fixed column cell, considering colspan.
     * @param {number} index - The index of the column.
     * @param {Array} row - The row to check.
     * @returns {Object} - The style object.
     */
    getCellStyle(index, row) {
      if (this.isFixedColumn(index, row)) {
        let left = 0;
        let count = 0;
        // Calculate the left offset for the fixed column
        for (let i = 0; i < row.length; i++) {
          const colspan = row[i].colspan || 1;
          count += colspan;
          if (index < count) {
            break;
          }
          if (this.columnWidths[i]) {
            left += this.columnWidths[i];
          }
        }
        return {
          left: `${left}px` // Set the left position for sticky columns
        };
      }
      return {};
    },
    /**
     * Calculates the widths of each column and stores them in an array.
     */
    calculateColumnWidths() {
      this.$nextTick(() => {
        if (this.$refs.firstRow && this.$refs.firstRow.length > 0) {
          const cells = this.$refs.firstRow[0].children;
          // Iterate over each cell in the first row to calculate and store widths
          for (let i = 0; i < cells.length; i++) {
            const cell = cells[i];
            const colspan = cell.colspan || 1;
            let width = 0;
            // Accumulate the width for cells with colspan
            for (let j = 0; j < colspan; j++) {
              width += cell.getBoundingClientRect().width;
            }
            this.$set(this.columnWidths, i, width); // Store the calculated width
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

/* Containers */
.oct-table {
  display: flex;
  width: 100%;
  overflow-x: auto;
  position: relative;
}

.oct-table__table {
  width: 100%;
  border-collapse: collapse;
}

.oct-table__text-container {
  display: flex;
  gap: oct-rem(6);
  justify-content: center;
  align-items: center;
}

/* Texts */
.oct-table__header-row {
  font-size: oct-rem(12);
  line-height: (16/12);
  color: $oct-theme--neutral-50;
}

.oct-table__row {
  font-size: oct-rem(16);
  line-height: (19/16);
  line-height: 1;
}

.oct-table__cell-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  gap: oct-rem(6);
  align-items: center;
  overflow: hidden;
}

.oct-table__filter-menu-heading {
  font-size: oct-rem(12);
  line-height: 1.2;
}

/* Elements */
.oct-table__header-cell,
.oct-table__cell {
  font-weight: normal;
  text-align: center;
  position: relative;
  padding: oct-rem(25.5) oct-rem(16);
  vertical-align: middle;
  background-color: $oct-theme--neutral-10;
  border-top: oct-rem(1) solid $oct-theme--neutral-20;

  &:first-child,
  .oct-table__header-cell--end + &,
  .oct-table__cell--end + & {
    padding-left: oct-rem(32);
  }

  &:last-child, &--end {
    padding-right: oct-rem(32);
  }

  &--active {
    font-weight: bold;
    color: $oct-theme--primary;
  }

  &--fixed {
    position: sticky;
    z-index: 2;
    background-color: $oct-theme--surface;
  }

  .oct-table__header-row:first-child & {
    border-width: 0;
  }
}

/* Add box-shadow to the right side of header-cell--end and cell--end */
.oct-table--overflow:not(.oct-table--scroll-end):after {
  flex: 0 0 oct-rem(32);
  content: '';
  position: sticky;
  margin-left: oct-rem(-32);
  right: oct-rem(0);
  top: 0;
  bottom: 0;
  width: oct-rem(32);
  background: linear-gradient(to left, rgba(0,0,0,.05), rgba(0,0,0,0));
  z-index: 4; /* Ensure the shadow is above other elements */
}

.oct-table--overflow:not(.oct-table--scroll-start) .oct-table__header-cell--end::before,
.oct-table--overflow:not(.oct-table--scroll-start) .oct-table__cell--end::before {
  content: '';
  position: absolute;
  right: oct-rem(-32);
  top: 0;
  bottom: 0;
  width: oct-rem(32);
  background: linear-gradient(to right, rgba(0,0,0,.05), rgba(0,0,0,0));
  z-index: 4; /* Ensure the shadow is above other elements */
}

.oct-table__header-cell {
  padding-top: oct-rem(16);
  padding-bottom: oct-rem(16);
  border-width: 0;
}

.oct-table__filter-icon {
  width: oct-rem(16);
  height: oct-rem(16);
  fill: $oct-theme--neutral-50;
  cursor: pointer;

  .oct-table__header-cell--active & {
    fill: $oct-theme--primary;
  }
}

.oct-table__filter-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: oct-rem(16);
  padding: oct-rem(32);
  background-color: $oct-theme--surface;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.oct-table__filter-menu-section {
  display: flex;
  flex-direction: column;
  gap: oct-rem(16);
  padding-bottom: oct-rem(16);
  border-bottom: oct-rem(1) solid $oct-theme--neutral-20;

  &:last-child {
    padding-bottom: 0;
    border-width: 0;
  }
}

.oct-table__filter-menu-item {
  display: flex;
  align-items: center;
  gap: oct-rem(8);
  min-width: oct-rem(172);
  cursor: pointer;
  height: oct-rem(19);
  padding: 0 0 0 oct-rem(16+8);

  &--active {
    padding-left: 0;
  }

  &::v-deep .oct-icon {
    display: block;
    margin: oct-rem(-4);
    width: oct-rem(24);
    height: oct-rem(24);
  }
}
</style>
