import Vue from 'vue'
import Vuex from 'vuex'
import API from '@/api/index.js'
import OctUsers from '@/store/modules/OctUsers.js'
import OctCustomers from '@/store/modules/OctCustomers.js'
import OctItems from '@/store/modules/OctItems.js'
import OctProducts from '@/store/modules/OctProducts.js'
import OctComments from '@/store/modules/OctComments.js'
import OctNews from '@/store/modules/OctNews.js'
import OctSalons from '@/store/modules/OctSalons.js'
import updateNotificationPlugin from '@/store/plugins/updateNotificationPlugin.js'

Vue.use(Vuex)

// Session key
const storageKey = '@@stylestockspajs@@'

/** Load session data from session storage */
const loadSessionData = () => (
  JSON.parse(sessionStorage.getItem(storageKey) || '{}')
)

export default new Vuex.Store({
  state: {
    /** Salon ID */
    id: '',
    /** Salon name */
    name: '',
    profileIcon: {
      id: '',
      lowResolution: '',
      standardResolution: '',
      highResolution: '',
    },
    /** Salon's status */
    status: '',
    /** Extra data */
    data: {},
    /** Indicate downloading data from API */
    processing: false,
    /** Error */
    error: null,
    /** Alert */
    alert: null,
    /** Confirmation */
    confirmation: null,
    /** Notification */
    notification: null,
    /** Session data */
    session: loadSessionData()
  },
  getters: {
    /** @returns Whether the current salon is a management salon or not */
    isManagement: state => state.id === '12000010',
    /** @returns Using iPad */
    isIPad () {
      return false
      /*
      // For iPadOS 13 or later
      if (navigator.userAgent.includes("Mac") && "ontouchend" in document) {
        return true;
      }
      // iPadOS 12 or earlier
      return navigator.userAgent.includes("iPad");
      */
    }
  },
  actions: {
    /*
    * Fetch salon
    * @param {string} id Salon ID to fetch
    */
    async fetch ({commit, state}, id=state.id) {
      return API.getSalon(id).then(response => {
        // Set default icon
        if (!response.profileIcon ||  !response.profileIcon.id)
          Object.assign(response.profileIcon, {
            standardResolution: '/images/salon__default.png',
            lowResolution: '/images/salon__default.png',
          })

        // Update state and returns response
        commit('state', response)
        return Promise.resolve(response)
      })
    },

    /** Alert message */
    async alert ({commit}, message) {
      commit('state', {alert: message})
      alert(message)
      return Promise.resolve()
    },

    /** Notify message */
    async notify ({commit}, message) {
      commit('state', {notification: message})
      return Promise.resolve()
    },

    /** Confirm message */
    async confirm ({commit}, message) {
      return new Promise ((resolve, reject) => {
        commit('state', {
          confirmation: {
            message: message.text ? message.text : message,
            resolve: resolve,
            reject: reject
          }
        })
      })
    }
  },
  mutations: {
    /** Error */
    error (state, thrown) {
      state.error = thrown
    },

    /** Session data */
    session (state, val) {
      Vue.set(state, 'session', val)
      // Store in session storage
      sessionStorage.setItem(storageKey, JSON.stringify(
        Object.assign(loadSessionData(), val)))
    },

    /** Set state */
    state (state, val) {
      Object.assign(state, val)
    }
  },
  modules: {
    /** Customers */
    customers: OctCustomers,
    /** Products */
    products: OctProducts,
    /** Treatments */
    treatments: OctItems,
    /** All staffs */
    staffs: OctUsers,
    /** Salon’s comments */
    comments: OctComments,
    /** News */
    news: OctNews,
    /** Salons */
    salons: OctSalons
  },
  plugins: [
    updateNotificationPlugin()
  ]
})
