var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"oct-list-item",class:{
    'oct-list-item--dense': _vm.dense,
    'oct-list-item--reverse': _vm.reverse,
    'oct-list-item--single': _vm.title && !_vm.text,
    'oct-list-item--with-icon': _vm.icon
  }},[_c(_vm.$scopedSlots.meta && !_vm.selectable ? 'label' : 'div',{tag:"component",staticClass:"oct-list-item__content"},[(_vm.selectable)?_c('div',{staticClass:"oct-list-item__action",on:{"click":function($event){$event.stopPropagation();}}},[_c('oct-checkbox',{staticClass:"oct-list-item__checkbox",attrs:{"value":_vm.value,"disabled":_vm.disabled},model:{value:(_vm.$_octLI_modelValue),callback:function ($$v) {_vm.$_octLI_modelValue=$$v},expression:"$_octLI_modelValue"}})],1):_vm._e(),((_vm.media && _vm.media.image && _vm.media.image.lowResolution) || _vm.icon)?_c('oct-media',{staticClass:"oct-list-item__graphic",attrs:{"image":{
        id: _vm.media && _vm.media.image && _vm.media.image.id,
        lowResolution: _vm.media && _vm.media.image && _vm.media.image.lowResolution || '',
        standardResolution: _vm.media && _vm.media.image && _vm.media.image.standardResolution || '',
        status: _vm.media && _vm.media.image && _vm.media.image.status
      },"alt":_vm.title}},[(_vm.icon)?_c('oct-icon',{staticClass:"oct-list-item__icon",attrs:{"icon":_vm.icon}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"oct-list-item__text"},[(_vm.text)?_c('span',{staticClass:"oct-list-item__secondary-text"},[_vm._v(_vm._s(_vm.text))]):_vm._e(),(_vm.title)?_c('span',{staticClass:"oct-list-item__primary-text"},[_vm._v(_vm._s(_vm.title))]):_vm._e()]),_c('div',{staticClass:"oct-list-item__meta"},[_vm._t("meta")],2),(_vm.$scopedSlots['trailing-icon'] && !_vm.removable)?_c('div',{staticClass:"oct-list-item__meta oct-list-item__meta--icon"},[_vm._t("trailing-icon")],2):_vm._e(),(_vm.removable)?_c('div',{staticClass:"oct-list-item__meta oct-list-item__meta--icon oct-list-item__meta--remove",on:{"click":function($event){return _vm.$emit('remove')}}},[_c('oct-icon',{attrs:{"icon":"close"}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }