var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"oct-table",class:{
  'oct-table--overflow': _vm.isOverflow,
  'oct-table--scroll-start': _vm.isScrollStart,
  'oct-table--scroll-end': _vm.isScrollEnd
},on:{"click":_vm.handleOutsideClick}},[_c('table',{staticClass:"oct-table__table"},[_c('thead',{staticClass:"oct-table__header"},_vm._l((_vm.headers),function(headerRow,rowIndex){return _c('tr',{key:rowIndex,staticClass:"oct-table__header-row"},_vm._l((headerRow),function(header,index){return _c('th',{key:index,ref:"headerCells",refInFor:true,staticClass:"oct-table__header-cell",class:{
            'oct-table__header-cell--fixed': _vm.isFixedColumn(index, headerRow),
            'oct-table__header-cell--end': _vm.isFixedColumn(index, headerRow) && !_vm.isFixedColumn(index+1, headerRow),
            'oct-table__header-cell--active': _vm.activeFilterGroupIndex === index
          },style:(_vm.getCellStyle(index, headerRow)),attrs:{"colspan":header.colspan || 1}},[_c('div',{staticClass:"oct-table__text-container"},[_c('span',{staticClass:"oct-table__cell-text"},[_vm._v(" "+_vm._s(header.text)+" ")]),(rowIndex === _vm.headers.length - 1 && _vm.filters && _vm.filters[index])?_c('oct-icon',{staticClass:"oct-table__filter-icon",attrs:{"icon":"filter"},nativeOn:{"click":function($event){return _vm.toggleFilterMenu(index, $event)}}}):_vm._e()],1)])}),0)}),0),_c('tbody',{staticClass:"oct-table__body"},_vm._l((_vm.tableData),function(row,rowIndex){return _c('tr',{key:rowIndex,ref:"firstRow",refInFor:true,staticClass:"oct-table__row",on:{"click":function($event){return _vm.$emit('click:row', row)}}},_vm._l((row),function(cell,cellIndex){return _c('td',{key:cellIndex,staticClass:"oct-table__cell",class:{
            'oct-table__cell--fixed': _vm.isFixedColumn(cellIndex, _vm.tableData[0]),
            'oct-table__cell--end': _vm.isFixedColumn(cellIndex, _vm.tableData[0]) && !_vm.isFixedColumn(cellIndex+1, _vm.tableData[0])
          },style:(_vm.getCellStyle(cellIndex, _vm.tableData[0])),attrs:{"data-text":cell}},[_c('div',{staticClass:"oct-table__text-container"},[_c('span',{staticClass:"oct-table__cell-text"},[_vm._v(" "+_vm._s(cell)+" ")])])])}),0)}),0)]),(_vm.isFilterMenuVisible)?_c('div',{ref:"filterMenu",staticClass:"oct-table__filter-menu",style:(_vm.filterMenuStyle),on:{"click":function($event){$event.stopPropagation();}}},_vm._l((_vm.filters[_vm.activeFilterIndex]),function(group,groupIndex){return _c('div',{key:groupIndex,staticClass:"oct-table__filter-menu-section"},[(group.groupName)?_c('div',{staticClass:"oct-table__filter-menu-heading"},[_vm._v(" "+_vm._s(group.groupName)+" ")]):_vm._e(),_vm._l((group.items),function(item,itemIndex){return _c('div',{key:itemIndex,staticClass:"oct-table__filter-menu-item",class:{ 'oct-table__filter-menu-item--active': item.active },on:{"click":function($event){return _vm.menuItemClicked(item)}}},[(item.active)?_c('oct-icon',{attrs:{"icon":"done"}}):_vm._e(),_vm._v(" "+_vm._s(item.text)+" ")],1)})],2)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }