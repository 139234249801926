var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.me.id),expression:"me.id"}],staticClass:"ss-dashboard",class:{
    'ss-dashboard--ipad': _vm.$store.getters['isIPad']
  }},[_c('div',{staticClass:"ss-dashboard-header"},[_c('div',{staticClass:"ss-dashboard-header__content"},[_c('div',{staticClass:"ss-dashboard-header__graphic",on:{"click":function($event){_vm.$router.push({
          name: 'Profile.mine'
        }).catch(() => {})}}},[_c('oct-media',{staticClass:"ss-dashboard-header__image",attrs:{"image":_vm.me.profileIcon,"alt":`${_vm.me.lastName} ${_vm.me.firstName}`}})],1),_c('div',{staticClass:"ss-dashboard-header__title"},[_c('span',{staticClass:"ss-dashboard-header__title-text"},[_vm._v(" "+_vm._s(_vm.$_octPrototypeView_getDateString(new Date))+" ")])]),(_vm.me.data.verified !== false)?_c('div',{staticClass:"ss-dashboard-header__actions"},[_c('div',{staticClass:"ss-dashboard-header__action",on:{"click":function($event){return _vm.$router.push({
            name: 'Comments.mine'
          })}}},[_c('oct-icon',{attrs:{"icon":"message-outline"}})],1),_c('div',{staticClass:"ss-dashboard-header__action",class:{
            'ss-dashboard-header__action--activated': _vm.unreadNotifications.length
          },on:{"click":function($event){return _vm.$router.push({
            name: 'Notifications'
          })}}},[(!_vm.unreadNotifications.length)?_c('oct-icon',{attrs:{"icon":"bel-outline"}}):_c('oct-icon',{attrs:{"icon":"bel"}})],1),_c('div',{staticClass:"ss-dashboard-header__action ss-dashboard-header__action--exit"},[_c('oct-icon',{attrs:{"icon":"exit"},nativeOn:{"click":function($event){return _vm.signOut.apply(null, arguments)}}})],1)]):_vm._e()])]),(_vm.items.length && !_vm.$store.state.processing && _vm.me.data.verified !== false)?_c('div',{staticClass:"ss-dashboard__content"},[_c('oct-list',{class:{
        'ss-dashboard__list--empty': !_vm.unsent.length 
      },attrs:{"title":"未送信"}},_vm._l((_vm.unsent),function(item){return _c('oct-list-item',_vm._b({key:item.id,nativeOn:{"click":function($event){_vm.$router.push({
          name: 'Treatment',
          params: {
            treatmentId: item.id
          }
        }).catch(() => {})}},scopedSlots:_vm._u([{key:"meta",fn:function(){return [_c('oct-chip',{attrs:{"dense":"","selected":""}},[_vm._v(" 未送信 ")])]},proxy:true},{key:"trailing-icon",fn:function(){return [_c('oct-icon',{attrs:{"icon":"chevron-right"}})]},proxy:true}],null,true)},'oct-list-item',_vm.itemToListItem(item, false, false),false))}),1),_c('oct-list',{class:{
        'ss-dashboard__list--empty': !_vm.sent.length 
      },attrs:{"title":"送信済"}},_vm._l((_vm.sent),function(item){return _c('oct-list-item',_vm._b({key:item.id,nativeOn:{"click":function($event){_vm.$router.push({
          name: 'Treatment',
          params: {
            treatmentId: item.id
          }
        }).catch(() => {})}},scopedSlots:_vm._u([{key:"meta",fn:function(){return [_c('oct-chip',{attrs:{"dense":""}},[_vm._v(" 送信済 ")])]},proxy:true},{key:"trailing-icon",fn:function(){return [_c('oct-icon',{attrs:{"icon":"chevron-right"}})]},proxy:true}],null,true)},'oct-list-item',_vm.itemToListItem(item, false, false),false))}),1)],1):(!_vm.$store.state.processing && _vm.me.data.verified !== false)?_c('div',{staticClass:"ss-dashboard__content ss-dashboard__content--empty"},[_vm._m(0)]):_vm._e(),(_vm.me.data.verified !== false)?_c('div',{staticClass:"ss-dashboard__action"},[_c('oct-button',{attrs:{"block":"","outline":"","size":"large","icon":"book"},nativeOn:{"click":function($event){_vm.$router.push({name:'Treatments'}).catch(()=>{})}}},[_vm._v(" 施術履歴 ")]),_c('oct-button',{staticClass:"ss-dashboard__secondary-action",attrs:{"block":"","outline":"","size":"large"},nativeOn:{"click":function($event){return _vm.$router.push({name: 'Treatments', query: {customer: true}})}},scopedSlots:_vm._u([{key:"trailing-icon",fn:function(){return [_c('oct-icon',{attrs:{"icon":"search"}})]},proxy:true}],null,false,3878980603)}),_c('oct-button',{staticClass:"ss-dashboard__secondary-action ss-dashboard__secondary-action--qr",attrs:{"block":"","size":"large"},nativeOn:{"click":function($event){return _vm.$router.push({name: 'QR'})}},scopedSlots:_vm._u([{key:"trailing-icon",fn:function(){return [_c('oct-icon',{attrs:{"icon":"qr"}})]},proxy:true}],null,false,2255389686)})],1):_vm._e(),_c('transition',{attrs:{"name":_vm.transitionName}},[_c('router-view')],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ss-dashboard-note"},[_c('span',{staticClass:"ss-dashboard-note__secondary-text"},[_vm._v(" まだお客様がいません ")]),_c('p',{staticClass:"ss-dashboard-note__primary-text"},[_vm._v(" QRコードをスキャンして、"),_c('br'),_vm._v("お客様を追加しましょう ")])])
}]

export { render, staticRenderFns }