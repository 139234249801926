<template>
  <li
    class="oct-list-item"
    :class="{
      'oct-list-item--dense': dense,
      'oct-list-item--reverse': reverse,
      'oct-list-item--single': title && !text,
      'oct-list-item--with-icon': icon
    }"
  >
    <component
      :is="$scopedSlots.meta && !selectable ? 'label' : 'div'"
      class="oct-list-item__content"
    >
      <div
        class="oct-list-item__action"
        @click.stop
        v-if="selectable"
      >
        <oct-checkbox
          :value="value"
          :disabled="disabled"
          v-model="$_octLI_modelValue"
          class="oct-list-item__checkbox"
        />
      </div>

      <oct-media
        :image="{
          id: media && media.image && media.image.id,
          lowResolution: media && media.image && media.image.lowResolution || '',
          standardResolution: media && media.image && media.image.standardResolution || '',
          status: media && media.image && media.image.status
        }"
        :alt="title"
        class="oct-list-item__graphic"
        v-if="(media && media.image && media.image.lowResolution) || icon"
      >
        <oct-icon
          :icon="icon"
          v-if="icon"
          class="oct-list-item__icon"
        />
      </oct-media>

      <div class="oct-list-item__text">
        <span class="oct-list-item__secondary-text" v-if="text">{{ text }}</span>
        <span class="oct-list-item__primary-text" v-if="title">{{ title }}</span>
      </div>

      <div class="oct-list-item__meta">
        <slot name="meta"/>
      </div>

      <div
        class="oct-list-item__meta oct-list-item__meta--icon"
        v-if="$scopedSlots['trailing-icon'] && !removable"
      >
        <slot name="trailing-icon"/>
      </div>

      <div
        class="oct-list-item__meta oct-list-item__meta--icon oct-list-item__meta--remove"
        v-if="removable"
        @click="$emit('remove')"
      >
        <oct-icon icon="close"/>
      </div>
    </component>
  </li>
</template>

<script>
import OctIcon from '@/components/icon/OctIcon.vue';
import OctMedia from '@/components/media/OctMedia.vue'
import OctCheckbox from '@/components/checkbox/OctCheckbox.vue'

export default {
  name: 'OctListItem',
  components: {
    OctIcon,
    OctMedia,
    OctCheckbox
  },
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    /** Graphic */
    media: {
      type: Object,
      default () {
        return {
          image: {
            standardResolution: '',
            lowResolution: '',
          }
        }
      }
    },
    /** Icon */
    icon: {
      type: String,
      default: ''
    },
    /** Title text */
    title: {
      type: [String,Number],
      default: ''
    },
    /** Text under the title */
    text: {
      type: [String,Number],
      default: ''
    },
    /** Value of the checkbox */
    value: {
      type: [String,Number,Boolean],
      default: ''
    },
    /** Model value pass to the checkbox */
    modelValue: {
      type: [Boolean,Array,String],
      default: false
    },
    /** Set checkbox disabled attribute */
    disabled: {
      type: Boolean,
      default: false
    },
    /** Indicate whether item can be selected */
    selectable: {
      type: Boolean,
      default: false
    },
    /** Whether item can be removed */
    removable: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    reverse: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /** Local model value */
    $_octLI_modelValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('change', value)
      }
    }
  },
  mounted () {
    // Stop remove icon’s event propagation
    this.removable &&
      this.$el.querySelector('.oct-list-item__meta--remove')
        .addEventListener('click', event => event.stopPropagation())
  }
}
</script>

<style scoped lang="scss">
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-list-item {
  cursor: pointer;
}

.oct-list-item__content {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.oct-list-item__graphic {
  margin: 0 oct-rem(16) 0 0;
  flex: 0 0 oct-rem(64);
  max-width: oct-rem(64);
  display: flex;
  justify-content: center;
  align-items: center;
  width: oct-rem(64);
  height: oct-rem(64);
  padding-top: 0;
  overflow: hidden;

  .oct-list-item--with-icon & {
    background-color: $oct-theme--neutral-10;
  }
}

.oct-list-item__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.oct-list-item__icon {
  fill: $oct-theme--neutral-50;
  width: oct-rem(32);
  height: oct-rem(32);
}

.oct-list-item__text {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .oct-list-item--reverse & {
    flex-direction: column-reverse;
  }
}

.oct-list-item__primary-text {
  font-size: oct-rem(20);
  line-height: 1.2;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  display: block;
  overflow: hidden;

  .oct-list-item--dense & {
    font-size: oct-rem(14);
    line-height: 1.2;
    white-space: initial;
    text-overflow: initial;
    overflow: visible;
  }

  .oct-list-item--reverse & {
    margin-bottom: oct-rem(8)
  }

  .oct-list-item--single & {
    color: $oct-theme--neutral-50;
  }
}

.oct-list-item__secondary-text {
  font-size: oct-rem(12);
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $oct-theme--neutral-50;
  margin: 0 0 (8em/12);
  display: block;
  overflow: hidden;

  .oct-list-item--reverse & {
    color: $oct-theme--neutral;
    margin-bottom: 0;
  }
}

.oct-list-item__meta {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin: 0 oct-rem(12) 0 0;

  &:last-child {
    margin-right: 0;
  }

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  margin-right: oct-rem(8);
}
}

.oct-list-item__meta--icon {
  position: relative;
  flex: 0 0 oct-rem(32);
  width: oct-rem(32);
  height: oct-rem(32);

  &:last-child {
    margin-right: 0;
  }

  .oct-icon {
    fill: $oct-theme--neutral-50;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.oct-list-item__meta--remove {
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: oct-rem(20);
    height: oct-rem(20);
    background-color: $oct-theme--neutral-20;
    border-radius: 50%;
  }

  .oct-icon {
    fill: $oct-theme--surface;
    width: oct-rem(22);
    height: oct-rem(22);
  }
}

.oct-select__dropdown-icon {
  position: absolute;
  top: 50%;
  right: oct-rem(17-4);
  width: oct-rem(20);
  height: oct-rem(20);
  transform: translateY(-50%);
  pointer-events: none;

  .oct-icon {
    width: 100%;
    height: 100%;
  }
}

.oct-list-item__action {
  margin: 0 oct-rem(16) 0 0;
  display: flex;
  align-items: center;

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  margin-right: oct-rem(32);
}
}
</style>
