<template>
  <div class="oct-pagination">
    <button
      class="oct-pagination__button oct-pagination__button--prev"
      :class="{ 'oct-pagination__button--disabled': currentPage === 1 }"
      @click="prevPage"
      v-if="currentPage !== 1"
    >
      <oct-icon
        icon="chevron-right"
      />
    </button>
    <button
      v-for="page in totalPages"
      :key="page"
      class="oct-pagination__button"
      :class="{ 'oct-pagination__button--active': currentPage === page }"
      @click="goToPage(page)"
    >
      {{ page }}
    </button>
    <button
      class="oct-pagination__button"
      :class="{ 'oct-pagination__button--disabled': currentPage === totalPages }"
      @click="nextPage"
      v-if="currentPage !== totalPages"
    >
      <oct-icon
        icon="chevron-right"
      />
    </button>
  </div>
</template>

<script>
import OctIcon from '@/components/icon/OctIcon.vue';

export default {
  name: 'OctPagination',
  components: {
    OctIcon
  },
  props: {
    /**
     * The total number of pages available for pagination.
     * @type {number}
     * @required
     */
    totalPages: {
      type: Number,
      required: true
    },
    /**
     * The current active page number.
     * @type {number}
     * @required
     */
    currentPage: {
      type: Number,
      required: true
    }
  },
  methods: {
    /**
     * Emit an event to change the current page to the specified page.
     * @param {number} page - The page number to navigate to.
     */
    goToPage(page) {
      this.$emit('update:currentPage', page);
    },
    /**
     * Emit an event to navigate to the previous page, if available.
     */
    prevPage() {
      if (this.currentPage > 1) {
        this.$emit('update:currentPage', this.currentPage - 1);
      }
    },
    /**
     * Emit an event to navigate to the next page, if available.
     */
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.$emit('update:currentPage', this.currentPage + 1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../theme/variables";
@import "../grid/variables";
@import "../typography/variables";
@import "../typography/mixins";

.oct-pagination {
  display: flex;
  list-style: none;
  gap: oct-rem(8);
  padding: 0;
  margin: 0;

  &__button {
    font-size: 1rem;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: oct-rem(40);
    height: oct-rem(40);
    padding: 0 oct-rem(4);
    border: oct-rem(1) solid $oct-theme--neutral-20;
    background-color: transparent;
    cursor: pointer;

    &--active, &:hover {
      color: $oct-theme--on-primary;
      fill: $oct-theme--on-primary;
      border-color: $oct-theme--neutral-70;
      background-color: $oct-theme--neutral-70;
    }

    &--disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &--prev {
      .oct-icon {
        transform: rotate(180deg);
      }
    }
  }
}
</style>